<template>
  <div class="container">
    <div class="columns">
      <div
        class="column content login-box card is-4 is-offset-4 has-text-centered"
      >
        <div class="p-md">
          <h3 class="">
            Your account has not been confirmed.
          </h3>
          <p>
            Please confirm your account before you use Vendor Payments.
            We sent a message to your e-mail when you created the account with a link to confirm it, if you did not get the e-mail,
            please do click on the "Send" button below to send you a new account confirmation e-mail.
          </p>
          <validation-observer
            ref="login-form"
            v-slot="validation"
            tag="div"
          >
            <input-form :errors="validation.errors">
              <div class="field">
                <vee-textbox
                  v-model="email"
                  name="E-mail"
                  placeholder="E-mail"
                  rules="required|email"
                  required
                />
              </div>
              <div class="field is-grouped is-grouped-centered">
                <div class="control">
                  <button
                    class="button is-primary"
                    :class="{ 'is-loading': sending }"
                    :disabled="sending"
                    @click.prevent="sendConfirmationEmail(validation)"
                  >
                    Send
                  </button>
                </div>
              </div>
              <p>
                <router-link :to="{ name: 'login' }">
                  Go to login
                </router-link>
              </p>
            </input-form>
          </validation-observer>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import InputForm from '@/components/InputForm';

import {
  Textbox,
} from '@phila/phila-ui';

import {
  extend,
  withValidation,
  ValidationObserver,
  setInteractionMode,
  validate,
} from 'vee-validate';

import { required, email } from 'vee-validate/dist/rules';
const VeeTextbox = withValidation(Textbox);

export default {
  name: "LoginPage",
  components: {
    VeeTextbox,
  },
  data() {
    return {
      email: '',
      sending: false,
    };
  },
  created () {
    setInteractionMode('passive');
    let self = this;
    extend('required', {
      ...required,
      message: 'The {_field_} field is required',
    });
    extend('email', {
      ...email,
    });
  },
  beforeMount() {
    if (this.$route.query.email) {
      this.email = this.$route.query.email;
    }
  },
  methods: {
    async sendConfirmationEmail(validation) {
      // const valid = await this.$refs['login-form'].validate();
      const valid = await validation.validate();
      if (!valid) {
        return false;
      }

      this.sending = true;
      const result = await this.$store.dispatch('login/sendConfirmationEmail', this.email);
      this.sending = false;
      if (result !== false) {
        this.email = '';
        this.$refs['login-form'].reset();
        this.$success("E-mail confirmation has been sent!");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
 .vertical-align-middle {
   * {
     vertical-align: middle;
   }
 }
</style>
